import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { scaleLinear, scaleTime } from 'd3-scale';
import {
  Box,
  Fab,
  Paper,
  Stack,
  Table,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  styled,
  Button,
  SvgIcon,
  Typography,
  useMediaQuery,
} from '@mui/material/';
import { PlayArrow, Restore, Square } from '@mui/icons-material';

import {
  daysOfTheWeek,
  useInterval,
  useWindowSize,
  hexToRgb,
  formatAMPM,
  generateDateTime,
  isSameDaySameHour,
  server,
  getVariableTimeseriesData,
  getPacificTime,
} from '../../utilities';
import { useDraggable } from 'react-use-draggable-scroll';
import './styles.css';
import axios from 'axios';
export default function TimeSlider({
  sliderCurrentTime,
  setSliderCurrentTime,
  sliderStartTime,
  stepsPerDay,
  numberOfDays,
  selectedMapLayer,
  pointLocations,
  selectedStation,
  mapLayerMaxTimes,
  settings,
  scrollTime,
  dataPanelOpen,
  dataPinDrawerShown,
  landOrWater,
  sliderPlayTime,
  setSliderPlayTime,
}) {
  const theme = useTheme();
  const size = useWindowSize();
  const scrollRef = useRef();
  const tableRef = useRef();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const { scale, isWaterVariable } = selectedMapLayer;
  const dataGradient = scaleLinear()
    .domain(scale.map((step) => step.value))
    .range(scale.map((step) => step.color))
    .unknown('#000000');
  const [dataColorScale, setDataColorScale] = useState();

  const oldPointLocations = useRef();
  const oldSelectedMapLayer = useRef();
  const oldSettings = useRef();
  useEffect(() => {
    if (!mapLayerMaxTimes) return;

    if (isWaterVariable && landOrWater === 'land' && dataPinDrawerShown) {
      // use land/not land check
      generateMapLayerColorScales([]);
    } else if (!pointLocations || !dataPinDrawerShown || !settings.showColorStrip) {
      // If no point selected && we have mapLayerMaxTimes, show mapLayer end colorStrip on timeslider
      generateMapLayerColorScales(mapLayerMaxTimes);
    } else if (
      // If pointlocations, or selectedMapLayer changesgenerate new coloStrip
      dataPinDrawerShown &&
      (oldPointLocations.current !== pointLocations ||
        oldSelectedMapLayer.current?.variableName !== selectedMapLayer?.variableName ||
        oldSettings.current !== settings ||
        settings.useCrosshair)
    ) {
      oldPointLocations.current = pointLocations;
      oldSelectedMapLayer.current = selectedMapLayer;
      oldSettings.current = settings;
      if (pointLocations) {
        axios
          .get(
            server +
              '/datapin/timeseries?' +
              new URLSearchParams({
                field: selectedMapLayer.variableName,
                lat: pointLocations[0],
                lon: pointLocations[1],
                base: landOrWater,
              }).toString()
          )
          .then((response) => {
            generateDataColorScale(
              getVariableTimeseriesData(
                response.data.data.filter(
                  (d) =>
                    new Date(d.date) <=
                    new Date(mapLayerMaxTimes[selectedMapLayer.variableName])
                ),
                selectedMapLayer?.variableName
              )
            );
          });
      }
    }
  }, [
    pointLocations,
    selectedMapLayer,
    mapLayerMaxTimes,
    settings.showColorStrip,
    dataPinDrawerShown,
  ]);

  const sliderEndDate = new Date(sliderStartTime);
  sliderEndDate.setDate(sliderEndDate.getDate() + 4);
  sliderEndDate.setHours(0, 0, 0, 0);
  const sliderEndTime = sliderEndDate.getTime();

  function generateMapLayerColorScales(data) {
    const mapLayerColorScale = [];
    for (let i = 0; i < numberOfDays * 24; i++) {
      mapLayerColorScale.push(
        new Date(sliderStartTime).getTime() + i * 60 * 60 * 1000 <=
          new Date(data[selectedMapLayer?.variableName]).getTime()
          ? theme.palette.primary.main
          : '#00000000'
      );
    }
    setDataColorScale(mapLayerColorScale);
  }

  function generateDataColorScale(data) {
    const dataInTimeframe = data.filter((elem) => {
      const elemTime = new Date(elem.date).getTime();
      return elemTime >= sliderStartTime && elemTime <= sliderEndTime;
    });
    const populatedHourlyTimeseriesData = [];
    for (let i = 0; i < numberOfDays * 24; i++) {
      const thisHoursData = dataInTimeframe.find((elem) => {
        return (
          new Date(sliderStartTime).getTime() + i * 60 * 60 * 1000 ===
          new Date(elem.date).getTime()
        );
      });

      populatedHourlyTimeseriesData.push(
        thisHoursData?.value?.toFixed(1)
          ? settings.showColorStrip
            ? dataGradient(thisHoursData.value)
            : theme.palette.primary.main
          : '#00000000'
      );
    }
    setDataColorScale(populatedHourlyTimeseriesData);
  }

  const { events } = useDraggable(scrollRef, {
    decayRate: 0, // instant stop
  });

  function handleSliderPlayTime() {
    window.clarity('event', 'Play button click');
    if (!sliderPlayTime) {
      if (sliderCurrentTime === sliderEndTime) {
        setSliderCurrentTime(sliderStartTime);
      }
    }
    setSliderPlayTime(!sliderPlayTime);
  }
  // https://overreacted.io/making-setinterval-declarative-with-react-hooks/
  useInterval(
    () => {
      const timeStep = (1000 * 60 * 60) / 2; // 1 hour
      if (sliderCurrentTime === sliderEndTime) {
        setSliderCurrentTime(sliderStartTime);
      }
      function takeStep(newTime) {
        newTime = sliderCurrentTime + timeStep;
        setSliderCurrentTime(newTime);
        setScrollPosition(`${formatAMPM(new Date(newTime))}`);
        scrollRef.current.scrollLeft = timeframe.current(newTime);
      }
      if (
        sliderCurrentTime + timeStep <= sliderEndTime &&
        sliderCurrentTime <=
          new Date(mapLayerMaxTimes[selectedMapLayer?.variableName]).getTime()
      ) {
        takeStep(sliderCurrentTime + timeStep);
      } else {
        // finished
        takeStep(sliderEndTime);
        setSliderPlayTime(false);
      }
    },
    sliderPlayTime ? 500 : null
  );

  const StyledTableCell = styled(TableCell, {
    shouldForwardProp: (prop) => prop !== 'showBorder',
  })(({ showBorder = true, theme }) => ({
    color: theme.palette.secondary.main,
    borderLeft: `2px solid ${theme.palette.secondary.main}${showBorder ? '80' : '00'}`,
    borderBottom: 'none',
    borderRight: `2px solid ${theme.palette.secondary.main}${showBorder ? '80' : '00'}`,
  }));

  const tableMinWidth = 1000;
  const [tableWidth, setTableWidth] = useState(window.innerWidth);
  const [scrollMax, setScrollMax] = useState(tableWidth);
  const [tableMargin, setTableMargin] = useState(200);
  const [scrollPosition, setScrollPosition] = useState('12 am');

  const daysInTimeframe = [];
  for (let index = 0; index < numberOfDays; index++) {
    const date = new Date(sliderStartTime);
    date.setDate(date.getDate() + index);
    daysInTimeframe.push({
      dayText: `${daysOfTheWeek[date.getDay()]}, ${date.getDate()}`,
    });
  }
  const timeframe = useRef(
    scaleTime()
      .domain([new Date(sliderStartTime), new Date(sliderEndTime)])
      .range([0, scrollMax])
  );

  function handleScroll() {
    scrollRef.current.scrollTop = 0;
    if (!sliderPlayTime) {
      if (scrollRef.current.scrollLeft > scrollMax) {
        scrollRef.current.scrollLeft = scrollMax;
      } else {
        const sliderTime = timeframe.current.invert(scrollRef.current.scrollLeft);
        setScrollPosition(`${formatAMPM(sliderTime)}`);
        const newSliderModelTime = generateDateTime(sliderTime);
        const oldSliderModelTime = generateDateTime(new Date(sliderCurrentTime));

        if (newSliderModelTime !== oldSliderModelTime) {
          setSliderCurrentTime(sliderTime.getTime());
        }
      }
    }

    window.clarity('event', 'Timeslider scroll');
  }

  function updateTimeSliderParameters() {
    if (scrollRef.current) {
      const tableWidthUpdate =
        window.innerWidth < tableMinWidth ? tableMinWidth + 50 : window.innerWidth; // 50px is total border width across the TimeSlider
      setTableWidth(tableWidthUpdate);
      setScrollMax(tableWidthUpdate);
      setTableMargin(scrollRef.current.offsetWidth / 2);
      timeframe.current.range([0, tableWidthUpdate]);
      const sliderTime = timeframe.current.invert(scrollRef.current.scrollLeft);
      setScrollPosition(`${formatAMPM(new Date(sliderTime))}`);

      scrollRef.current.scrollLeft = timeframe.current(sliderCurrentTime);
    }
  }

  useLayoutEffect(() => {
    updateTimeSliderParameters();
  }, [size]);

  function updateTimeSliderToMatchChartSlider() {
    scrollTime?.getMinutes() === 0 && scrollTime?.setMinutes(10);
    scrollRef.current.scrollLeft = timeframe.current(scrollTime);
  }

  useEffect(() => {
    if (scrollTime !== undefined && !mobileView) {
      updateTimeSliderToMatchChartSlider();
    }
  }, [scrollTime]);

  useEffect(() => {
    if (scrollTime !== undefined && mobileView && dataPanelOpen === false) {
      updateTimeSliderToMatchChartSlider();
    }
  }, [dataPanelOpen]);

  function handleResetTime() {
    scrollRef.current.scrollLeft = timeframe.current(
      new Date(getPacificTime()).getTime()
    );
  }

  const timesliderAtCurrentTime = isSameDaySameHour(
    sliderCurrentTime,
    new Date(getPacificTime()).getTime()
  );

  return (
    <Box
      position="fixed"
      sx={{
        top: 'auto',
        bottom: 10,
        margin: '0px 10px 0px 10px',
        width: 'calc(100% - 20px)',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          bottom: '0px',
          left: '0px',
        }}
      >
        <Fab
          variant="contained"
          color="primary"
          sx={{
            width: '48px',
            height: '48px',
            aspectRatio: 1,
            // border: `2px solid ${theme.palette.primary.dark}`,
            backgroundColor: theme.palette.primary.main,
            '&:disabled': {
              backgroundColor: theme.palette.primary.main,
            },
            textTransform: 'none',
          }}
          onClick={handleSliderPlayTime}
          disabled={
            mapLayerMaxTimes !== undefined &&
            sliderCurrentTime >
              new Date(mapLayerMaxTimes[selectedMapLayer?.variableName]).getTime()
          }
        >
          <Stack alignItems="center">
            {sliderPlayTime ? <Square /> : <PlayArrow />}
            <Typography sx={{ fontSize: '10px' }} color="secondary">
              {/* {sliderPlayTime ? 'Pause' : 'Play'} */}
            </Typography>
          </Stack>
        </Fab>
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.secondary.main,
          position: 'absolute',
          top: '0px',
          left: '50%',
          width: '2px',
          height: '16.5px',
          pointerEvents: 'none',
          zIndex: 100,
        }}
      >
        <Button
          color="secondary"
          variant="contained"
          sx={{
            position: 'absolute',
            top: '-25px',
            left: '-45px',
            width: '90px',
            height: '24px',
            pointerEvents: timesliderAtCurrentTime ? 'none' : 'auto',
            // border: `2px solid ${theme.palette.primary.light}`,
            borderRadius: '24px',
            padding: '0px',
            textTransform: 'none',
          }}
          onClick={() => {
            window.clarity('event', "Timeslider 'go to now' click");
            handleResetTime();
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0.5}
          >
            <Typography width="50px" variant="body2" color="primary">
              {scrollPosition}
            </Typography>
            {!timesliderAtCurrentTime && (
              <SvgIcon>
                <Restore color="primary" />
              </SvgIcon>
            )}
          </Stack>
        </Button>
      </Box>
      <Stack direction="row" spacing="10px">
        <Paper
          className="timeSliderPaper"
          elevation={6}
          sx={{
            backgroundColor: `rgba(${hexToRgb(theme.palette.primary.main)}, 0.7)`,
            borderRadius: '50px',
            flex: 1,
            overflowX: 'scroll',
            overflowY: 'hidden',
            scrollBehavior: 'instant',
            display: 'flex',
            height: '48px',
          }}
          onScroll={() => handleScroll()}
          ref={scrollRef}
          {...events}
        >
          <Box position="relative">
            <Box
              position="absolute"
              sx={{
                top: '0px',
                left: `${
                  timeframe.current(new Date(getPacificTime()).getTime()) + tableMargin
                }px`,
                height: '48px',
                width: '2px',
                borderLeft: `2px dashed ${theme.palette.secondary.main}`,
                opacity: 0.5,
                zIndex: 99,
              }}
            />
          </Box>
          <Table
            size="small"
            sx={{
              width: `${tableWidth}px`,
              maxWidth: `${tableWidth}px`,
              fontWeight: 'bold',
              overflowY: 'hidden',
              color: theme.palette.secondary.main,
              margin: `0px ${tableMargin}px 0px ${tableMargin}px`,
              backgroundColor: theme.palette.primary.main,
              background:
                dataColorScale === undefined
                  ? theme.palette.primary.main
                  : `linear-gradient(to right, ${dataColorScale?.toString()}), repeating-linear-gradient( -45deg, ${
                      theme.palette.primary.main
                    }, ${theme.palette.primary.main} 2px, #5481a0 2px, #5481a0 8px)`,
            }}
            ref={tableRef}
            onMouseDown={(event) => {
              event.preventDefault();
              setSliderPlayTime(false);
            }}
            onTouchStart={() => setSliderPlayTime(false)}
          >
            <TableBody>
              <TableRow sx={{ borderBottom: '0px' }}>
                {daysInTimeframe.map((day, index) => {
                  return (
                    <StyledTableCell
                      colSpan={stepsPerDay}
                      key={index}
                      sx={{
                        height: '24px',
                        padding: '0px 10px 0px 10px',
                        minWidth: `${tableWidth / numberOfDays}px`,
                        position: 'relative', // Make the cell a positioning context
                        overflow: 'hidden', // Ensure the background doesn't overflow
                      }}
                    >
                      {/* Background Box */}
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: '50%', // Adjust this value to set the starting point horizontally
                          width: '50%', // Adjust this value to set the width of the background
                          height: '100%',
                          // backgroundColor: theme.palette.secondary.main, // Set the desired background color
                          zIndex: 0, // Ensure the background is behind the text
                        }}
                      />

                      {/* Mid-day Tick */}
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: '50%', // Center the tick mark horizontally
                          width: '2px', // Set the width of the tick mark
                          height: '25%', // Set the height of the tick mark
                          opacity: 0.5, // Set the opacity of the tick mark
                          backgroundColor: '#FFFFFF', // Set the color of the tick mark
                          zIndex: 1, // Ensure the tick mark is above the background
                        }}
                      />

                      {/* Content */}
                      <Box
                        sx={{
                          position: 'relative',
                          zIndex: 2, // Ensure the text is above the background and tick mark
                          fontSize: '1.5em',
                          color: '#FFFFFF', // Set a contrasting text color
                          textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)', // Add a shadow to the text
                          // backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Add a semi-transparent background
                          padding: '2px 4px', // Optional: Add padding to the text
                          borderRadius: '4px', // Optional: Add rounded corners to the background
                        }}
                      >
                        {`${day.dayText}`}
                      </Box>
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
          <Box position="relative">
            <Box
              position="absolute"
              sx={{
                top: '0px',
                left: `${
                  timeframe.current(new Date(getPacificTime()).getTime()) + tableMargin
                }px`,
                height: '55px',
                width: '2px',
                borderLeft: `1px dashed ${theme.palette.secondary.main}`,
                zIndex: 99,
              }}
            />
          </Box>
        </Paper>
      </Stack>
    </Box>
  );
}
