import React, { useState } from 'react';

import {
  Dialog,
  List,
  ListItemText,
  ListItemButton,
  Toolbar,
  IconButton,
  Typography,
  Divider,
  Switch,
  useMediaQuery,
  useTheme,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  ListItem,
  Collapse,
  Box,
  Stack,
  Tooltip,
  styled,
  Link,
  Snackbar,
} from '@mui/material/';

import {
  Launch,
  Close as CloseIcon,
  ExpandMore,
  ExpandLess,
  DeleteSweepRounded,
  RotateLeftRounded,
  Chat,
  InfoOutlined,
  CopyrightRounded,
  CheckCircle,
  Email,
} from '@mui/icons-material';
import units from '../units.js';
import { capitalizeFirstLetter, defaultSettings } from '../utilities.js';

import ForecastOn from '../Images/forecastOn.png';
import ForecastOff from '../Images/forecastOff.png';
import Crosshair from '../Images/Crosshair.png';
import Marker from '../Images/Marker.png';
import AnimationOn from '../Images/AnimationOn.png';
import AnimationOff from '../Images/AnimationOff.png';
import colorDataEnabled from '../Images/colorDataEnabled.png';
import colorDataDisabled from '../Images/colorDataDisabled.png';
import Instagram from '../Images/Instagram_Glyph_White.svg';
import XLogo from '../Images/X-logo-white.svg';
import HakaiLogo from '../Images/HakaiLogo.png';

import MailchimpForm from './MailchimpForm.jsx';

export default function SettingsMenu({
  mainMenuOpen,
  setMainMenuOpen,
  setSettings,
  settings,
  cookieNames,
}) {
  const theme = useTheme();

  const [unitsDrawerCollapsed, setUnitsDrawerCollapsed] = useState(true);
  const [forecastDrawerCollapsed, setForecastDrawerCollapsed] = useState(true);
  const [pointerDrawerCollpased, setPointerDrawerCollapsed] = useState(true);
  const [animationDrawerCollapsed, setAnimationDrawerCollapsed] = useState(true);
  const [colorDataDrawerCollapsed, setColorDataDrawerCollapsed] = useState(true);

  const uniq = (arr) => [...new Set(arr)];
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const unitsCategories = uniq(units.map((e) => e.category));

  const logoNumber = ['03', '05', '07'];
  const [logoIndex, setLogoIndex] = useState(0);

  const SettingsSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        '& .MuiSwitch-thumb': {
          backgroundColor: theme.palette.secondary.main,
        },
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.primary.dark,
          opacity: 1,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.secondary.main,
    },
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.primary.dark,
      opacity: 1,
    },
  }));

  const [settingsResetSnackBarOpen, setSettingsResetSnackBarOpen] = useState(false);
  function settingsResetClick() {
    setSettingsResetSnackBarOpen(true);
  }
  function settingsResetClose() {
    setSettingsResetSnackBarOpen(false);
  }

  const [cookiesDeleteSnackBarOpen, setCookiesDeleteSnackBarOpen] = useState(false);
  function cookiesDeleteClick() {
    setCookiesDeleteSnackBarOpen(true);
  }
  function cookiesDeleteClose() {
    setCookiesDeleteSnackBarOpen(false);
  }

  function closeSettingsMenu() {
    setMainMenuOpen(false);
    setAnimationDrawerCollapsed(true);
    setColorDataDrawerCollapsed(true);
    setForecastDrawerCollapsed(true);
    setPointerDrawerCollapsed(true);
    setUnitsDrawerCollapsed(true);
  }

  return (
    <Dialog
      fullScreen={mobileView}
      open={mainMenuOpen}
      sx={{ pointerEvents: 'auto' }}
      onClose={(e, reason) => reason === 'backdropClick' && closeSettingsMenu()}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.main,
        },
      }}
    >
      <Toolbar sx={{ backgroundColor: theme.palette.primary.light }}>
        <Typography color="secondary" sx={{ flex: 1 }} variant="h6" component="div">
          Settings
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => closeSettingsMenu()}
          aria-label="close"
        >
          <CloseIcon color="secondary" />
        </IconButton>
      </Toolbar>
      <Divider />
      <List sx={{ margin: '0px 10px', minWidth: mobileView ? '' : '400px' }}>
        <ListItemButton
          onClick={() => {
            setUnitsDrawerCollapsed(!unitsDrawerCollapsed);
            setForecastDrawerCollapsed(true);
            setPointerDrawerCollapsed(true);
            setAnimationDrawerCollapsed(true);
            setColorDataDrawerCollapsed(true);
          }}
          sx={{
            backgroundColor: unitsDrawerCollapsed
              ? theme.palette.primary.main
              : theme.palette.primary.light,
          }}
        >
          <ListItemText sx={{ ml: 2, flex: 1 }}>
            <Typography variant="button">Units</Typography>
          </ListItemText>
          {unitsDrawerCollapsed ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>
        <Collapse in={!unitsDrawerCollapsed}>
          <Divider />
          <List sx={{ backgroundColor: theme.palette.primary.light }}>
            {unitsCategories.map((category) => (
              <ListItem key={category}>
                <FormControl>
                  <FormLabel>
                    <Typography color="secondary">
                      {capitalizeFirstLetter(category)}
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    row
                    onChange={(e) => {
                      setSettings({
                        ...settings,
                        units: { ...settings.units, [category]: e.target.value },
                      });
                    }}
                    name={category}
                    value={settings.units[category]}
                  >
                    {units
                      .filter((e) => e.category === category)
                      .map((e) => (
                        <FormControlLabel
                          color="secondary"
                          key={e.display}
                          value={e.name}
                          control={
                            <Radio
                              sx={{
                                color: theme.palette.primary.dark,
                                '&.Mui-checked': {
                                  color: theme.palette.secondary.main,
                                },
                              }}
                            />
                          }
                          label={e.display}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              </ListItem>
            ))}
            <Typography
              variant="body2"
              sx={{
                width: '100%',
                textAlign: 'center',
                height: 'fit-content',
                padding: '10px 0px',
              }}
            >
              <InfoOutlined
                color="secondary"
                fontSize="small"
                sx={{ margin: '0px 5px -5px 0px' }}
              />
              Set your preferred units here, or toggle units with the legend
            </Typography>
          </List>
        </Collapse>
        <Divider />
        <ListItemButton
          onClick={() => {
            setForecastDrawerCollapsed(!forecastDrawerCollapsed);
            setUnitsDrawerCollapsed(true);
            setPointerDrawerCollapsed(true);
            setAnimationDrawerCollapsed(true);
            setColorDataDrawerCollapsed(true);
          }}
          sx={{
            backgroundColor: forecastDrawerCollapsed
              ? theme.palette.primary.main
              : theme.palette.primary.light,
          }}
        >
          <ListItemText sx={{ ml: 2, flex: 1 }}>
            <Typography variant="button">Forecast Bar</Typography>
          </ListItemText>
          {forecastDrawerCollapsed ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>
        <Collapse in={!forecastDrawerCollapsed}>
          <Divider />
          <Box sx={{ backgroundColor: theme.palette.primary.light }}>
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              sx={{ height: '60px', padding: '5px' }}
            >
              <Typography>Hidden</Typography>
              <SettingsSwitch
                checked={settings.showColorStrip}
                onClick={() =>
                  setSettings({ ...settings, showColorStrip: !settings.showColorStrip })
                }
                color="secondary"
              />
              <Typography>Shown</Typography>
            </Stack>
            <Box sx={{ overflow: 'hidden' }}>
              <img
                style={{
                  height: '40px',
                  aspectRatio: 'auto',
                  display: 'block',
                  margin: 'auto',
                }}
                src={settings.showColorStrip ? ForecastOn : ForecastOff}
              />
            </Box>
            <Typography
              variant="body2"
              sx={{
                width: '100%',
                textAlign: 'center',
                height: 'fit-content',
                padding: '10px 0px',
              }}
            >
              <InfoOutlined
                color="secondary"
                fontSize="small"
                sx={{ margin: '0px 5px -5px 0px' }}
              />
              Show or hide a forecast through time
            </Typography>
          </Box>
        </Collapse>
        <Divider />
        <ListItemButton
          onClick={() => {
            setPointerDrawerCollapsed(!pointerDrawerCollpased);
            setUnitsDrawerCollapsed(true);
            setForecastDrawerCollapsed(true);
            setAnimationDrawerCollapsed(true);
            setColorDataDrawerCollapsed(true);
          }}
          sx={{
            backgroundColor: pointerDrawerCollpased
              ? theme.palette.primary.main
              : theme.palette.primary.light,
          }}
        >
          <ListItemText sx={{ ml: 2, flex: 1 }}>
            <Typography variant="button">Pointer type</Typography>
          </ListItemText>
          {pointerDrawerCollpased ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>
        <Collapse in={!pointerDrawerCollpased}>
          <Divider />
          <Box sx={{ backgroundColor: theme.palette.primary.light }}>
            <Stack direction="row" justifyContent="space-around" alignItems="center">
              <Typography>Marker</Typography>
              <SettingsSwitch
                checked={settings.useCrosshair}
                onClick={() =>
                  setSettings({
                    ...settings,
                    useCrosshair: !settings.useCrosshair,
                  })
                }
              />
              <Typography>Crosshair</Typography>
            </Stack>
            <Box sx={{ overflow: 'hidden', alignContent: 'center' }}>
              <Box
                style={{
                  height: '50px',
                  width: '100%',
                  aspectRatio: 'auto',
                  display: 'block',
                  margin: 'auto',
                  background: `url(${
                    settings.useCrosshair ? Crosshair : Marker
                  }) 50% 50% / contain no-repeat`,
                }}
              />
            </Box>
            <Typography
              variant="body2"
              sx={{
                width: '100%',
                textAlign: 'center',
                height: 'fit-content',
                padding: '10px 0px',
              }}
            >
              <InfoOutlined
                color="secondary"
                fontSize="small"
                sx={{ margin: '0px 5px -5px 0px' }}
              />
              Use marker placement or crosshair position to access data
            </Typography>
          </Box>
        </Collapse>
        <Divider />
        <ListItemButton
          onClick={() => {
            setAnimationDrawerCollapsed(!animationDrawerCollapsed);
            setForecastDrawerCollapsed(true);
            setUnitsDrawerCollapsed(true);
            setPointerDrawerCollapsed(true);
            setColorDataDrawerCollapsed(true);
          }}
          sx={{
            backgroundColor: animationDrawerCollapsed
              ? theme.palette.primary.main
              : theme.palette.primary.light,
          }}
        >
          <ListItemText sx={{ ml: 2, flex: 1 }}>
            <Typography variant="button">Animations</Typography>
          </ListItemText>
          {animationDrawerCollapsed ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>
        <Collapse in={!animationDrawerCollapsed}>
          <Divider />
          <Box sx={{ backgroundColor: theme.palette.primary.light }}>
            <Stack direction="row" justifyContent="space-around" alignItems="center">
              <Typography>Animation off</Typography>
              <SettingsSwitch
                checked={settings.showAnimations}
                onClick={() =>
                  setSettings({
                    ...settings,
                    showAnimations: !settings.showAnimations,
                  })
                }
              />
              <Typography>Animation on</Typography>
            </Stack>
            <Box sx={{ overflow: 'hidden', alignContent: 'center' }}>
              <Box
                style={{
                  height: '50px',
                  width: '100%',
                  aspectRatio: 'auto',
                  display: 'block',
                  margin: 'auto',
                  background: `url(${
                    settings.showAnimations ? AnimationOn : AnimationOff
                  }) 50% 50% / contain no-repeat`,
                }}
              />
            </Box>
            <Typography
              variant="body2"
              sx={{
                width: '100%',
                textAlign: 'center',
                height: 'fit-content',
                padding: '10px 0px',
              }}
            >
              <InfoOutlined
                color="secondary"
                fontSize="small"
                sx={{ margin: '0px 5px -5px 0px' }}
              />
              Enable moving animations on map layers that support direction
            </Typography>
          </Box>
        </Collapse>
        <Divider />
        <ListItemButton
          onClick={() => {
            setColorDataDrawerCollapsed(!colorDataDrawerCollapsed);
            setForecastDrawerCollapsed(true);
            setUnitsDrawerCollapsed(true);
            setPointerDrawerCollapsed(true);
            setAnimationDrawerCollapsed(true);
          }}
          sx={{
            backgroundColor: colorDataDrawerCollapsed
              ? theme.palette.primary.main
              : theme.palette.primary.light,
          }}
        >
          <ListItemText sx={{ ml: 2, flex: 1 }}>
            <Typography variant="button">Color Data</Typography>
          </ListItemText>
          {colorDataDrawerCollapsed ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>
        <Collapse in={!colorDataDrawerCollapsed}>
          <Divider />
          <Box sx={{ backgroundColor: theme.palette.primary.light }}>
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              sx={{ height: '60px', padding: '5px' }}
            >
              <Typography>Disabled</Typography>
              <SettingsSwitch
                checked={settings.colorData}
                onClick={() =>
                  setSettings({ ...settings, colorData: !settings.colorData })
                }
                color="secondary"
              />
              <Typography>Enabled</Typography>
            </Stack>
            <Box sx={{ overflow: 'hidden', alignContent: 'center' }}>
              <Box
                style={{
                  height: '50px',
                  width: '100%',
                  aspectRatio: 'auto',
                  display: 'block',
                  margin: 'auto',
                  background: `url(${
                    settings.colorData ? colorDataEnabled : colorDataDisabled
                  }) 50% 50% / contain no-repeat`,
                }}
              />
            </Box>
            <Typography
              variant="body2"
              sx={{
                width: '100%',
                textAlign: 'center',
                height: 'fit-content',
                padding: '10px 10px',
              }}
            >
              <InfoOutlined
                color="secondary"
                fontSize="small"
                sx={{ margin: '0px 5px -5px 0px' }}
              />
              Enable or disable data values being colored. Colored data is outlined when
              contrast is low with background.
            </Typography>
          </Box>
        </Collapse>
      </List>
      <Toolbar sx={{ backgroundColor: theme.palette.primary.light }}>
        <Typography color="secondary" sx={{ flex: 1 }} variant="h6" component="div">
          Links
        </Typography>
        <Divider />
      </Toolbar>
      <Divider />
      <List sx={{ margin: '0px 10px 0px 25px', minWidth: mobileView ? '' : '400px' }}>
        <ListItemButton href="https://forms.gle/qCbNJePnpVqXj6zn8" target="_blank">
          <ListItemText sx={{ flex: 1 }}>Feedback and bug reports</ListItemText>
          <Chat color="secondary" />
        </ListItemButton>
        <ListItemButton href="https://hakai.org" target="_blank">
          <Box sx={{ overflow: 'hidden', flex: 1 }}>
            <img
              style={{
                height: '40px',
                aspectRatio: 'auto',
                display: 'block',
              }}
              src={HakaiLogo}
            />
          </Box>
          <Launch color="secondary" />
        </ListItemButton>
        <Divider />
        <ListItemButton href={process.env.DOCS_URL + '/data_sources/'} target="_blank">
          <ListItemText sx={{ flex: 1 }}>Data sources</ListItemText>
          <Launch color="secondary" />
        </ListItemButton>
        <Divider />
        <ListItemButton href={process.env.DOCS_URL + '/privacy/'} target="_blank">
          <ListItemText sx={{ flex: 1 }}>Privacy policy</ListItemText>
          <Launch color="secondary" />
        </ListItemButton>
        <Divider />
        <ListItemButton href={process.env.DOCS_URL} target="_blank">
          <ListItemText color="secondary" sx={{ flex: 1 }}>
            About the service
          </ListItemText>
          <Launch color="secondary" />
        </ListItemButton>
      </List>
      <Toolbar sx={{ backgroundColor: theme.palette.primary.light }}>
        <Typography color="secondary" sx={{ flex: 1 }} variant="h6" component="div">
          Newsletter
        </Typography>
      </Toolbar>
      <Divider />
      <Box sx={{ margin: '0px 20px' }}>
        <MailchimpForm signupMode="settings" />
        <Divider />
      </Box>
      <Stack direction="row" spacing={0} margin="auto" paddingTop="10px">
        <Box
          component="img"
          src={require(`../Images/oceanconnectlogo-color-${logoNumber[logoIndex]}.svg`)}
          height="100px"
          width="50px"
          marginLeft="12px"
          marginBottom="12px"
          onClick={() => {
            setLogoIndex((logoIndex + 1) % 3);
          }}
        />
        <Typography sx={{ cursor: 'default', lineHeight: 0.5 }}>&trade;</Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={{ height: '60px' }}
      >
        <Box sx={{ width: '41px', height: '41px', padding: '7px' }}>
          <a href="https://twitter.com/OceanConnect">
            <img src={XLogo} width={27} height={27} />
          </a>
        </Box>
        <Box
          sx={{
            padding: '5px',
            width: '41px',
            height: '41px',
            borderRadius: '5px',
          }}
        >
          <a
            href="https://www.instagram.com/oceanconnect.ca/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Instagram} width={30} height={30} />
          </a>
        </Box>
        <Box sx={{ width: '40px', height: '35px', paddingLeft: '5px' }}>
          <a href="mailto:feedback@oceanconnect.ca" target="_blank" rel="noreferrer">
            <Email color="secondary" fontSize="large" />
          </a>
        </Box>
      </Stack>
      <List>
        <ListItem>
          <Stack
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
            sx={{ width: '100%' }}
          >
            <Stack>
              <Tooltip title="Reset settings to default">
                <IconButton
                  color="secondary"
                  size="small"
                  variant="contained"
                  sx={{
                    borderRadius: '25px',
                    backgroundColor: theme.palette.primary.light,
                    border: '2px transparent solid',
                    '&:hover': {
                      border: `2px ${theme.palette.secondary.main} solid`,
                    },
                  }}
                  onClick={() => {
                    setSettings(defaultSettings);
                    settingsResetClick();
                  }}
                >
                  <RotateLeftRounded />
                </IconButton>
              </Tooltip>
              <Typography variant="caption">Reset settings</Typography>
              <Snackbar
                open={settingsResetSnackBarOpen}
                autoHideDuration={3000}
                onClose={settingsResetClose}
                message={
                  <>
                    Settings reset
                    <CheckCircle
                      fontSize="small"
                      sx={{ marginLeft: '5px', marginBottom: '-5px' }}
                    />
                  </>
                }
                action={
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={settingsResetClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
                ContentProps={{
                  sx: {
                    background: theme.palette.success.main,
                  },
                }}
              />
            </Stack>
            <Stack>
              <Tooltip title="Delete saved preferences from cookies">
                <IconButton
                  color="secondary"
                  size="small"
                  variant="contained"
                  sx={{
                    borderRadius: '25px',
                    backgroundColor: theme.palette.primary.light,
                    border: '2px transparent solid',
                    '&:hover': {
                      border: `2px ${theme.palette.secondary.main} solid`,
                    },
                  }}
                  onClick={() => {
                    cookieNames.forEach((cookieName) => {
                      localStorage.removeItem(cookieName);
                    });
                    cookiesDeleteClick();
                  }}
                >
                  <DeleteSweepRounded />
                </IconButton>
              </Tooltip>
              <Typography variant="caption">Delete cookies</Typography>
              <Snackbar
                open={cookiesDeleteSnackBarOpen}
                autoHideDuration={3000}
                onClose={cookiesDeleteClose}
                message={
                  <>
                    Cookies deleted
                    <CheckCircle
                      fontSize="small"
                      sx={{ marginLeft: '5px', marginBottom: '-5px' }}
                    />
                  </>
                }
                action={
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={cookiesDeleteClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
                ContentProps={{
                  sx: {
                    background: theme.palette.success.main,
                  },
                }}
              />
            </Stack>
          </Stack>
        </ListItem>
      </List>
      <Divider />
      <Box sx={{ width: '100%', padding: 'auto', textAlign: 'center' }}>
        <Typography variant="caption" color="secondary">
          <CopyrightRounded fontSize="sm" />{' '}
          <Link color="secondary" href="/" target="_blank">
            OceanConnect&trade;
          </Link>{' '}
          by{' '}
          <Link color="secondary" href="https://tula.org" target="_blank">
            Tula Foundation
          </Link>{' '}
          is licensed under{' '}
          <Link
            color="secondary"
            href="http://creativecommons.org/licenses/by-nc-sa/4.0/"
            target="_blank"
          >
            CC BY-NC-SA 4.0
          </Link>
        </Typography>
      </Box>
    </Dialog>
  );
}
