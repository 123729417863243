import React from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import { ArrowUpward } from '@mui/icons-material';
import { colorContrasts, hexToRgb } from '../../utilities';

export default function DataElement({
  value,
  label,
  dataColor,
  units,
  direction,
  noData = false,
  variant,
  underlineUnits = true,
  backgroundColor,
}) {
  const theme = useTheme();
  return (
    <Stack
      justifyContent="flex-end"
      alignItems="center"
      sx={{
        height: '100%',
      }}
    >
      <Stack direction="row" alignItems="flex-end" spacing={0.5}>
        {direction !== undefined && !noData ? (
          <ArrowUpward
            color="secondary"
            sx={{
              transform: `rotate(${direction}deg)`,
              width: '35px',
              height: '35px',
            }}
          />
        ) : (
          <Typography
            variant={typeof value === 'number' && !noData ? variant || 'h3' : 'h6'} // 'h4'
            color={
              typeof value === 'number' && dataColor !== undefined && !noData
                ? colorContrasts(
                    dataColor,
                    `rgb(${hexToRgb(theme.palette.secondary.main)})`
                  )
                  ? theme.palette.secondary.main
                  : theme.palette.primary.dark
                : theme.palette.secondary.main
            }
            sx={{
              borderRadius: '5px',
              backgroundColor: noData ? backgroundColor : dataColor,
              padding: '0px 3px',
            }}
          >
            {!noData ? value : 'No data'}
          </Typography>
        )}
        {!noData && (
          <Typography
            variant="h7"
            color="secondary"
            sx={{
              textDecoration: underlineUnits ? 'underline' : 'none',
              textDecorationStyle: 'dotted',
              textDecorationThickness: '1.5px',
              paddingBottom: '3px',
              // make units line up vertically
              lineHeight: variant === 'h4' ? '35px' : '25px',
            }}
          >
            {units}
          </Typography>
        )}
      </Stack>
      <Typography variant="caption" color="secondary">
        {label}
      </Typography>
    </Stack>
  );
}
