import React, { useRef, useEffect } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';

import mapboxgl, { Point } from 'mapbox-gl';
import { useTheme } from '@mui/material';
import NoDataPattern from '../../Images/backgroundNoDataPattern.png';
import stationTypes from '../../stations.js';

import './styles.css';
import mapLayers from '../../mapLayers';
import {
  generateDateTime,
  checkLandOrWater,
  mapboxToken,
  getTileName,
} from '../../utilities';
import { VectorField, VectorImage } from '../../Vectors/lib';

import buoyIcon from '../../Images/Buoy_Station.png';
import tidesIcon from '../../Images/Tide_Station.png';
import webcamsIcon from '../../Images/Webcam_Station.png';
import weatherStationIcon from '../../Images/Weather_Station.png';
import currentsStationIcon from '../../Images/Current_Station.png';

const stationIcons = {
  buoys: buoyIcon,
  tide_stations: tidesIcon,
  webcams: webcamsIcon,
  weather_stations: weatherStationIcon,
  currents_stations: currentsStationIcon,
};

// defaults to the "dev" tiles
export const tilesURLPrefix =
  process.env.TILES_URL || 'https://d2zehncouvcmlt.cloudfront.net';

export default function Map({
  stations,
  showStationType,
  selectedStation,
  handleSelectStation,
  setPointLocations,
  zoom,
  setZoom,
  selectedVariableName,
  flyToCoords,
  setMapPanned,
  setLandOrWater,
  settings,
  sliderCurrentTime,
  mapLayerMaxTimes,
  mapCenterPoint,
  setMapCenterPoint,
  dataPanelOpen,
  webcamDialogOpen,
  cookiesChecked,
  setMapDataLoaded,
  setMapStyleLoaded,
  mapStyleLoaded,
  tileTimesLookup,
}) {
  const stationsShowZoomLevel = 10;
  const mapContainer = useRef(null);
  const map = useRef(null);
  const vectorFieldRef = useRef(null);
  const vectorFieldCIOPSWest = useRef(null);
  const selectedVariableNameRef = useRef();
  const markers = useRef(null);
  const hoveredStationIds = useRef(
    stationTypes.reduce((acc, station) => ({ ...acc, [station.type]: null }), {})
  );
  const layerOrder = stationTypes
    .map((station) => [station.type + 'CircleLayer', station.type + 'IconLayer'])
    .flat();
  const initialLoad = useRef(false);

  const modelLayersRef = useRef();
  const selectedStationIDRef = useRef();
  const selectedStationTypeRef = useRef();
  const stationsRef = useRef(stations);

  const tileTimesLookupRef = useRef(tileTimesLookup);
  useEffect(() => {
    tileTimesLookupRef.current = tileTimesLookup;
  }, [tileTimesLookup]);

  const dateTimeRef = useRef(generateDateTime(new Date(sliderCurrentTime)));
  useEffect(() => {
    dateTimeRef.current = generateDateTime(new Date(sliderCurrentTime));
  }, [sliderCurrentTime]);

  const cookiesCheckedRef = useRef(cookiesChecked);
  useEffect(() => {
    cookiesCheckedRef.current = cookiesChecked;
  }, [cookiesChecked]);

  mapboxgl.accessToken = mapboxToken;

  const theme = useTheme();
  const defaultMaxBounds = [
    [-160, 25],
    [-90, 70],
  ];
  const CIOPSWestVariables = ['potentialTemperature', 'current', 'seaSurfaceHeight'];

  const isInsideEndOfDataRef = useRef(true);
  const useCrosshairRef = useRef(settings.useCrosshair);
  useEffect(() => {
    useCrosshairRef.current = settings.useCrosshair;
  }, [settings.useCrosshair]);

  useEffect(() => {
    if (useCrosshairRef.current) {
      clearMarkers();
      updateCrossHair();
    } else {
      setPointLocations();
    }
  }, [useCrosshairRef.current]);

  const showAnimationsRef = useRef(settings.showAnimations);
  useEffect(() => {
    showAnimationsRef.current = settings.showAnimations;
  }, [settings.showAnimations]);

  useEffect(() => {
    if (map.current && map.current.isStyleLoaded()) {
      if (showAnimationsRef.current) {
        updateVectorAnimationLayer();
      } else {
        removeAnimationLayers();
      }
    }
  }, [showAnimationsRef.current]);

  const mapCenterPointRef = useRef();
  useEffect(() => {
    if (
      map.current &&
      mapCenterPointRef.current === undefined &&
      mapCenterPoint !== undefined
    ) {
      map.current.setCenter([mapCenterPoint[0], mapCenterPoint[1]]);
      mapCenterPointRef.current = mapCenterPoint;
    }
  }, [mapCenterPoint]);

  const zoomRef = useRef();

  function addSymbolLayer(symbolName, symbolData) {
    if (map.current) {
      // if (error) throw error;
      // if (!map.current.hasImage(`${symbolName}Icon`))
      // map.current.addImage(`${symbolName}Icon`, image, { sdf: true });

      map.current.addSource(`${symbolName}Source`, {
        type: 'geojson',
        data: symbolData,
        generateId: true,
        clusterMaxZoom: 14,
        clusterRadius: 35,
        maxzoom: stationsShowZoomLevel,
      });

      map.current.addLayer({
        id: `${symbolName}CircleLayer`,
        type: 'circle',
        source: `${symbolName}Source`,
        layout: {
          visibility: 'none',
        },
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-radius': 15,
          'circle-color': [
            'case',
            ['boolean', ['feature-state', 'click'], false],
            theme.palette.icons[symbolName].light,
            theme.palette.secondary.main,
          ],
          'circle-stroke-width': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            4,
            ['boolean', ['feature-state', 'click'], false],
            4,
            2,
          ],
          'circle-stroke-color': theme.palette.primary.main,
        },
      });

      map.current.addLayer({
        id: `${symbolName}IconLayer`,
        type: 'symbol',
        source: `${symbolName}Source`,
        layout: {
          visibility: 'none',
          'icon-image': `${symbolName}Icon`,
          'icon-size': 0.5,
          'icon-allow-overlap': true,
        },
        paint: {
          'icon-color': theme.palette.primary.main,
        },
        filter: ['!', ['has', 'point_count']],
      });

      map.current.addLayer({
        id: `${symbolName}ClusterLayer`,
        type: 'circle',
        source: `${symbolName}Source`,
        layout: {
          visibility: 'none',
        },
        filter: ['has', 'point_count'],
        paint: {
          'circle-radius': ['step', ['get', 'point_count'], 18, 5, 20, 10, 22],
          'circle-color': [
            'case',
            ['boolean', ['feature-state', 'click'], false],
            theme.palette.icons[symbolName].light,
            theme.palette.secondary.main,
          ],
          'circle-stroke-width': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            4,
            ['boolean', ['feature-state', 'click'], false],
            4,
            2,
          ],
          'circle-stroke-color': theme.palette.primary.main,
        },
      });

      map.current.addLayer({
        id: `${symbolName}ClusterCountLayer`,
        type: 'symbol',
        source: `${symbolName}Source`,
        filter: ['has', 'point_count'],
        layout: {
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
          // visibility: 'none',
        },
      });

      map.current.on('click', `${symbolName}ClusterLayer`, (e) => {
        if (!useCrosshairRef.current) clearMarkers();

        const features = map.current.queryRenderedFeatures(e.point, {
          layers: [`${symbolName}ClusterLayer`],
        });
        const clusterId = features[0].properties.cluster_id;

        map.current
          .getSource(`${symbolName}Source`)
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

              map.current.easeTo({
                center: features[0].geometry.coordinates,
                zoom,
            });
            if (!useCrosshairRef.current) setDataPinDrawerOpen(false);
          });
      });
      if (showStationType?.type === symbolName) {
        hideAllSymbolLayers();
        showSymbolLayer(symbolName);
      }
    }
  }

  function hideSymbolLayer(stationType) {
    if (map.current && map.current.getLayer(`${stationType}CircleLayer`)) {
      hideLayer(`${stationType}CircleLayer`);
    }
    if (map.current && map.current.getLayer(`${stationType}IconLayer`)) {
      hideLayer(`${stationType}IconLayer`);
    }
    if (map.current && map.current.getLayer(`${stationType}ClusterLayer`)) {
      hideLayer(`${stationType}ClusterLayer`);
    }
    if (map.current && map.current.getLayer(`${stationType}ClusterCountLayer`)) {
      hideLayer(`${stationType}ClusterCountLayer`);
    }
  }

  function showSymbolLayer(stationType) {
    if (map.current && map.current.getLayer(`${stationType}CircleLayer`)) {
      showLayer(`${stationType}CircleLayer`);
    }
    if (map.current && map.current.getLayer(`${stationType}IconLayer`)) {
      showLayer(`${stationType}IconLayer`);
    }
    if (map.current && map.current.getLayer(`${stationType}ClusterLayer`)) {
      showLayer(`${stationType}ClusterLayer`);
    }
    if (map.current && map.current.getLayer(`${stationType}ClusterCountLayer`)) {
      showLayer(`${stationType}ClusterCountLayer`);
    }

    if (showStationType && map.current.getSource(`${showStationType.type}Source`)) {
      const style = map?.current?.getStyle();
      style.sources[`${showStationType.type}Source`].cluster = true;
      map?.current?.setStyle(style);
    }
  }

  function hideAllSymbolLayers() {
    hideZoomedLayers();

    if (map.current) {
      stationTypes.forEach(({ type }) => {
        hideSymbolLayer(type);
      });
    }
  }

  function easeMapTo(lng, lat) {
    map.current.easeTo({
      center: [lng, lat],
      speed: 0.9,
    });
  }

  function handleStationClick(event, stationType) {
    setPointLocations([event.lngLat.lat, event.lngLat.lng]);
    event.clickOnLayer = true;
    clearMarkers();
    easeMapTo(event.lngLat.lng, event.lngLat.lat);
    clearSelectedStations();
    const stationID = event.features[0].id;
    // map.current.setFeatureState(
    //   {
    //     source: `${stationType}Source`,
    //     id: stationID,
    //   },
    //   {
    //     click: true,
    //   }
    // );

    handleSelectStation(event.features[0].properties);

    selectedStationIDRef.current = stationID;
    selectedStationTypeRef.current = stationType;
  }

  function clearSelectedStations() {
    if (!map.current) return;
    if (
      selectedStationTypeRef.current !== null &&
      selectedStationTypeRef.current !== undefined &&
      typeof selectedStationIDRef.current === 'number'
    ) {
      // check if source exists first
      if (map.current.getSource(`${selectedStationTypeRef.current}Source`)) {
        map.current.removeFeatureState(
          {
            source: `${selectedStationTypeRef.current}Source`,
            id: selectedStationIDRef.current,
          },
          'click'
        );
      }
    }
  }

  function clearMarkers() {
    if (markers.current) {
      markers.current.remove();
    }
  }

  function handleStationMouseOver(event, stationType) {
    hoveredStationIds.current[stationType] = event.features[0].id;
    map.current.setFeatureState(
      {
        source: `${stationType}Source`,
        id: hoveredStationIds.current[stationType],
      },
      {
        hover: true,
      }
    );
  }

  function handleStationMouseLeave() {
    for (const sT in hoveredStationIds.current) {
      if (typeof hoveredStationIds.current[sT] === 'number') {
        map.current.removeFeatureState(
          {
            source: `${sT}Source`,
            id: hoveredStationIds.current[sT],
          },
          'hover'
        );
      }
    }
  }

  function addStationEventHandlers() {
    stationTypes.forEach(({ type }) => {
      map.current.on('click', `${type}CircleLayer`, (event) => {
        if (event.originalEvent.cancelBubble) {
          return;
        }
        handleStationClick(event, type);

        setPointLocations([event.lngLat.lat, event.lngLat.lng]);

        event.originalEvent.cancelBubble = true;
      });
      map.current.on('click', `AllStationsCircleLayer-zoomed`, (event) => {
        if (event.originalEvent.cancelBubble) {
          return;
        }
        handleStationClick(event, type);

        setPointLocations([event.lngLat.lat, event.lngLat.lng]);

        event.originalEvent.cancelBubble = true;
      });
      map.current.on('mouseover', `${type}CircleLayer`, (event) => {
        handleStationMouseOver(event, type);
      });
      map.current.on('mouseleave', `${type}CircleLayer`, (event) => {
        handleStationMouseLeave();
      });
    });
  }

  useEffect(() => {
    // POINT SOURCE SYMBOL LAYERS
    if (showStationType) {
      stations?.forEach((station) => {
        if (station.stationType === showStationType.type) {
          showSymbolLayer(showStationType.type);
        } else {
          hideSymbolLayer(station.stationType);
        }
      });
      hideZoomedLayers();
    } else {
      hideAllSymbolLayers();
      showZoomedLayers();
    }
  }, [showStationType, map.current?.getLayer('AllStationsCircleLayer-zoomed')]);
  function showZoomedLayers() {
    if (map.current) {
      const zoomedLayers = [
        'AllStationsCircleLayer-zoomed',
        'AllStationsIconLayer-zoomed',
        'AllStationsClusterLayer-zoomed',
        'AllStationsClusterCountLayer-zoomed',
      ];

      zoomedLayers.forEach((layerId) => {
        if (map.current.getLayer(layerId)) {
          map.current.setLayoutProperty(layerId, 'visibility', 'visible');
        }
      });
    }
  }

  function hideZoomedLayers() {
    if (map.current && map.current.getLayer(`AllStationsCircleLayer-zoomed`)) {
      hideLayer(`AllStationsCircleLayer-zoomed`);
      hideLayer(`AllStationsIconLayer-zoomed`);
      hideLayer(`AllStationsClusterLayer-zoomed`);
      hideLayer(`AllStationsClusterCountLayer-zoomed`);
    }
  }

  useEffect(() => {
    if (!map.current?.isStyleLoaded()) return;
    // load images
    stations?.forEach((d) => {
      if (d.stationType === showStationType?.type) {
        if (!map.current.getLayer(d.stationType + 'CircleLayer')) {
          addSymbolLayer(d.stationType, d.stationData);
        }
      }
    });
    stationsRef.current = stations;
  }, [stations, map.current?.isStyleLoaded()]);

  function removeLayers(layers) {
    if (layers !== undefined && layers.length !== 0) {
      layers.forEach((layer) => {
        if (map.current.getLayer(layer.id)) {
          map.current.removeLayer(layer.id);
        }

        if (map.current.getSource(layer.source)) {
          map.current.removeSource(layer.source);
        }
      });
    }
  }

  function hideLayer(layerID) {
    map.current.setLayoutProperty(layerID, 'visibility', 'none'); // vs "visible"
  }

  function showLayer(layerID) {
    map.current.setLayoutProperty(layerID, 'visibility', 'visible'); // vs "visible"
  }

  useEffect(() => {
    if (markers.current && !selectedStation) {
      markers.current.remove();
      clearSelectedStations();
      setPointLocations();
    }
  }, []);

  function setMarker(lat, lon) {
    handleSelectStation();
    map.current.easeTo({
      center: [lon, lat],
      speed: 0.2,
    });

    // open the data panel

    // remove existing markers
    if (markers.current) {
      markers.current.remove();
    }
    // make new marker
    const marker = new mapboxgl.Marker({
      draggable: true,
      clickTolerance: 20,
      color: theme.palette.primary.main,
    })
      .setLngLat([lon, lat])
      .addTo(map.current);
    // add the marker to the list
    markers.current = marker;
    setPointLocations([lat, lon]);

    function onDragEnd() {
      // get new data at that point
      setPointLocations([
        markers.current.getLngLat().lat,
        markers.current.getLngLat().lng,
      ]);
      markers.current.dragging = false;
    }

    function onDragStart() {
      markers.current.dragging = true;
    }

    marker.on('dragend', onDragEnd);
    marker.on('dragstart', onDragStart);
  }

  // parameter is the name of the current model layer
  function reorderLayers(layerID = '') {
    if (map.current.isStyleLoaded()) {
      const availLayers = map.current?.getStyle()?.layers?.map((layer) => layer.id);

      if (availLayers.includes('background')) map.current.moveLayer('background');
      if (availLayers.includes(layerID)) map.current.moveLayer(layerID);
      availLayers
        .filter((layer) => layer !== layerID)
        .forEach((layer) => {
          map.current.moveLayer(layer);
        });

      layerOrder.forEach((layer) => {
        availLayers
          .filter((availLayer) => availLayer.includes(layer))
          .forEach((availLayer) => {
            map.current.moveLayer(availLayer);
          });
      });
    }
  }

  function getModelLayers() {
    if (map.current && cookiesCheckedRef.current) {
      return map.current
        .getStyle()
        .layers.filter((layer) => layer.id.startsWith('model_layer'))
        .reverse();
    }
  }

  function updateVectorAnimationLayer() {
    const { isVector, vectorSpeed, modelLongName } = mapLayers.find(
      (e) => e.variableName === selectedVariableNameRef.current
    );

    if (!isVector || !tileTimesLookupRef.current) return;

    vectorFieldRef?.current?.stopAnimation();
    vectorFieldCIOPSWest?.current?.stopAnimation();
    const range = [vectorSpeed * -200, vectorSpeed * 200];

    const boundsVariableMap = {
      HRDPS: [-150, 38, -120, 68],
      RDWPS: [-150, 38, -120, 68],
      'CIOPS-SalishSea': [-126.2, 47, -121.11319, 51],
      'CIOPS-West': [-140, 44.3, -122, 60],
    };
    let bounds = boundsVariableMap[modelLongName];

    const { tileName, modelRun } = getTileName(
      modelLongName,
      dateTimeRef.current,
      tileTimesLookupRef.current,
      selectedVariableNameRef.current
    );
    const url = `${tilesURLPrefix}/${modelLongName}/${modelRun}/${selectedVariableNameRef.current}/${tileName}/${tileName}.webp`;
    const isCurrents = selectedVariableNameRef.current === 'current' ? 1 : 0;
    let customLayer;

    // CIOPS-West isn't requested explicitly, it comes with CIOPS-SalishSea
    const isCIOPSWest = false;

    VectorImage(url, bounds, range)
      .then((vectorData) => {
        customLayer = {
          id: 'vectorField',
          type: 'custom',
          onAdd: function (map, gl) {
            if (vectorFieldRef.current) {
              vectorFieldRef.current.stopAnimation();
            }
            // create new VectorField instance
            vectorFieldRef.current = VectorField(map, gl);
            // set data for vector field
            vectorFieldRef.current.setData(vectorData, selectedVariableNameRef.current);
          },
          render: function (gl, matrix) {
            // on Custom Layer render, draw the vector field
            vectorFieldRef.current.draw(isCIOPSWest, isCurrents);
          },
          renderingMode: '3d',
        };

        if (map.current.getLayer('vectorField')) map.current.removeLayer('vectorField');
        map.current.addLayer(customLayer, 'landbase');
      })
      .catch((e) => {
        console.error(e);
      });

    if (modelLongName === 'CIOPS-SalishSea') {
      // If we have just added CIOPS-SalishSea, add CIOPS-West now
      const isCIOPSWest = true;

      bounds = boundsVariableMap['CIOPS-West'];
      const westURL = url.replace(/SalishSea/g, 'West');
      VectorImage(westURL, bounds, range)
        .then((vectorData) => {
          customLayer = {
            id: 'vectorFieldCIOPSWest',
            type: 'custom',
            onAdd: function (map, gl) {
              // remove any existing vector field
              if (vectorFieldCIOPSWest.current) {
                vectorFieldCIOPSWest.current.stopAnimation();
              }

              // create new VectorField instance
              vectorFieldCIOPSWest.current = VectorField(map, gl);
              // set data for vector field
              vectorFieldCIOPSWest.current.setData(
                vectorData,
                selectedVariableNameRef.current
              );
            },
            render: function (gl, matrix) {
              // on Custom Layer render, draw the vector field
              vectorFieldCIOPSWest.current.draw(isCIOPSWest, isCurrents);
            },
            renderingMode: '3d',
          };

          if (map.current.getLayer('vectorFieldCIOPSWest'))
            map.current.removeLayer('vectorFieldCIOPSWest');
          map.current.addLayer(customLayer, 'landbase');
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }

  useEffect(() => {
    if (!dataPanelOpen && !webcamDialogOpen) {
      clearSelectedStations();
      selectedStationIDRef.current = undefined;
    }
  }, [dataPanelOpen, webcamDialogOpen]);

  useEffect(() => {
    if (map.current && zoomRef.current === undefined && zoom !== false) {
      map.current.setZoom(zoom);
      zoomRef.current = zoom;
    }
  }, [zoom]);

  useEffect(() => {
    if (mapLayerMaxTimes) {
      isInsideEndOfDataRef.current =
        sliderCurrentTime <=
        new Date(mapLayerMaxTimes[selectedVariableName]).getTime() + 1000 * 60 * 60;
    }
    updateCrossHair();
  }, [mapLayerMaxTimes, sliderCurrentTime, selectedVariableName]);

  const oldTime = useRef();
  const oldVariable = useRef();

  useEffect(() => {
    const selectedVariableChanged = oldVariable.current !== selectedVariableName;
    if (selectedVariableChanged) {
      oldVariable.current = selectedVariableName;
    }

    const dateTimeChanged =
      oldTime.current !== generateDateTime(new Date(sliderCurrentTime));
    if (dateTimeChanged) {
      oldTime.current = generateDateTime(new Date(sliderCurrentTime));
    }
    const updateMap = selectedVariableChanged || dateTimeChanged;
    selectedVariableNameRef.current = selectedVariableName;

    if (map.current && updateMap) {
      let updateCalled = false; // ensure that updateMapWithLayer is only called once

      const updateMapWithLayer = () => {
        modelLayersRef.current = getModelLayers();
        updateMapLayerStyle();
        updateMapLayerSource();
        cleanUpMapLayers();
        reorderLayers();
        updateCalled = true;
      };

      if (map.current.isStyleLoaded()) {
        updateMapWithLayer();
      }
      // this was running on initail load but should only run on subsequent loads (when layers change quickly).
      else if (initialLoad.current) {
        if (!updateCalled) updateMapWithLayer();
      }
    }

    if (useCrosshairRef.current && !dataPanelOpen) {
      updateCrossHair();
    }
  }, [selectedVariableName, sliderCurrentTime]);

  useEffect(() => {
    if (!map.current) return;
    if (selectedStation || useCrosshairRef.current) {
      // if a station is selected, then zoom to that
      easeMapTo(flyToCoords[0], flyToCoords[1]);
    } else {
      // otherwise, zoom to the marker
      setMarker(flyToCoords[1], flyToCoords[0]);
    }
  }, [flyToCoords]);

  function updateCrossHair() {
    if (map.current) {
      const crosshairXY = new Point(window.innerWidth / 2, window.innerHeight / 2);

      const landOrWater = checkLandOrWater(map.current, crosshairXY);
      setLandOrWater(landOrWater);
      const centerLngLat = map.current.unproject(crosshairXY);
      setPointLocations([centerLngLat.lat, centerLngLat.lng]);
    }
  }

  function handleMapInteractionStart() {
    setMapPanned(true);
    if (vectorFieldRef?.current) {
      // stop animation and clear canvas
      vectorFieldRef.current.stopAnimation();
    }
    if (vectorFieldCIOPSWest?.current) {
      // stop animation and clear canvas
      vectorFieldCIOPSWest.current.stopAnimation();
    }
  }

  function handleMapInteractionEnd() {
    setMapPanned(false);
    setMapCenterPoint([map.current.getCenter().lng, map.current.getCenter().lat]);
    if (
      useCrosshairRef.current &&
      typeof selectedStationIDRef.current !== 'number' &&
      !selectedStationIDRef.current !== undefined
    ) {
      updateCrossHair();
    }
    if (vectorFieldRef?.current) {
      // start animating again
      vectorFieldRef.current.startAnimation(map.current);
    }
    if (vectorFieldCIOPSWest?.current) {
      // start animating again
      vectorFieldCIOPSWest.current.startAnimation(map.current);
    }
  }

  // Depends on map existing
  function addMapBackground() {
    if (
      map.current &&
      !map.current.hasImage('noDataPattern') &&
      map.current.getLayer('background') === undefined
    ) {
      map.current.loadImage(NoDataPattern, (err, image) => {
        // Throw an error if something goes wrong.
        if (err) throw err;

        // Add the image to the map style.
        if (!map.current.hasImage('noDataPattern')) {
          map.current.addImage('noDataPattern', image);
          const layers = map.current.getStyle().layers;
          // Create a new layer and style it using `fill-pattern`.
          map.current.addLayer(
            {
              id: 'background',
              type: 'background',
              paint: {
                'background-pattern': 'noDataPattern',
              },
            },
            layers[0].id
          );
        }
      });
    }
  }

  const listenerAddedRef = useRef(false);

  useEffect(() => {
    // This is run first and adds the styles and sources
    // Also runs on play
    if (
      tileTimesLookupRef.current &&
      dateTimeRef.current &&
      !listenerAddedRef.current &&
      mapStyleLoaded
    ) {
      updateMapLayerStyle();
      updateMapLayerSource();
      const handleResize = () => {
        if (showAnimationsRef.current && isInsideEndOfDataRef.current) {
          updateVectorAnimationLayer();
        }
      };

      window.addEventListener('resize', handleResize);

      // Set the ref to true to indicate that the listener has been added
      listenerAddedRef.current = true;

      // Clean up the event listener when the component unmounts or when tileTimesLookup or dateTime changes
      return () => {
        window.removeEventListener('resize', handleResize);
        listenerAddedRef.current = false; // Reset the ref so the listener can be added again
      };
    }
  }, [tileTimesLookup, dateTimeRef.current, selectedVariableName, mapStyleLoaded]);

  function cleanUpMapLayers() {
    if (map.current && map.current.isStyleLoaded() && cookiesCheckedRef.current) {
      if (modelLayersRef.current && modelLayersRef.current.length > 1) {
        const isIncluded = CIOPSWestVariables.includes(selectedVariableNameRef.current);

        modelLayersRef.current.forEach((layer, index) => {
          if ((isIncluded && index >= 2) || (!isIncluded && index >= 1)) {
            removeLayers([layer]);
          }
        });
      }
      if (
        map.current &&
        mapLayerMaxTimes &&
        selectedVariableNameRef.current &&
        !isInsideEndOfDataRef.current
      ) {
        modelLayersRef.current.forEach((layer) => {
          removeLayers([layer]);
        });
        removeAnimationLayers();
      }
    }
  }

  // Depends on map existing, on styles being loaded, and cookies being checked
  // Depends on external refs: map, cookiesCheckedRef, selectedVariableNameRef,
  function updateMapLayerStyle() {
    if (map.current && cookiesCheckedRef.current) {
      const isFullMapLayer = ['wind', 'airTemperature'].includes(
        selectedVariableNameRef.current
      );
      map?.current?.getStyle().layers.forEach((layer) => {
        if (layer.id === 'waterway') {
          map.current.setLayoutProperty(layer.id, 'visibility', 'none');
        } else if (
          layer.id === 'landbase' ||
          layer.id === 'landbase lowZoom' ||
          layer.id === 'building' ||
          layer.id === 'land-structure-line' ||
          layer.id === 'land-structure-polygon'
        ) {
          map.current.setLayoutProperty(
            layer.id,
            'visibility',
            isFullMapLayer ? 'none' : 'visible'
          );
        } else if (layer.id.includes('label')) {
          if (layer.id.includes('water')) {
            map.current.setPaintProperty(layer.id, 'text-halo-width', 1.5);
            map.current.setPaintProperty(layer.id, 'text-halo-color', 'white');
            map.current.setPaintProperty(layer.id, 'text-halo-blur', 2);
            map.current.setPaintProperty(layer.id, 'text-color', 'black');
          } else {
            map.current.setPaintProperty(
              layer.id,
              'text-halo-width',
              isFullMapLayer ? 1 : 0
            );
            map.current.setPaintProperty(layer.id, 'text-halo-blur', 2);
            map.current.setPaintProperty(layer.id, 'text-halo-color', 'black');
            map.current.setPaintProperty(layer.id, 'text-color', 'white');
          }
        } else if (
          layer.id.includes('boundary') ||
          layer.id.includes('bridge') ||
          layer.id.includes('road') ||
          layer.id.includes('tunnel')
        ) {
          map.current.setPaintProperty(
            layer.id,
            'line-color',
            isFullMapLayer ? 'black' : '#9ca7ab'
          );
        } else if (
          layer.id === 'landbaseOutline' ||
          layer.id === 'landbaseOutline lowZoom'
        ) {
          map.current.setLayoutProperty(layer.id, 'visibility', 'visible');
        }
      });
    }
  }

  function removeAnimationLayers() {
    if (map.current.getLayer('vectorField')) {
      map.current.removeLayer('vectorField');
    }
    if (map.current.getLayer('vectorFieldCIOPSWest')) {
      map.current.removeLayer('vectorFieldCIOPSWest');
    }
  }

  // Depends on map existing, on styles being loaded and cookies being checked
  // Depends on external refs: map, cookiesCheckedRef, selectedVariableNameRef, dateTime, tilesURLPrefix
  function updateMapLayerSource() {
    // Adding this here as modelLayersRef.current and getModelLayers() were getting out of sync when playing, resulting in extra layers being saved
    modelLayersRef.current = getModelLayers();
    if (
      map.current &&
      cookiesCheckedRef.current &&
      tileTimesLookupRef.current &&
      (JSON.parse(localStorage.getItem('ocSelectedVariableName')) ===
        selectedVariableNameRef.current ||
        JSON.parse(localStorage.getItem('ocSelectedVariableName')) === null)
    ) {
      const { variableName, maxzoom, isVector, modelLongName } = mapLayers.find(
        (layer) => layer.variableName === selectedVariableNameRef.current
      );

      const variableAndTime = variableName + '_' + dateTimeRef.current;
      const sourceID = 'model_source_' + variableAndTime;
      const layerID = 'model_layer_' + variableAndTime;

      // detect varable change and remove old variable's layers
      if (
        map.current.modelVariable !== undefined &&
        map.current.modelVariable !== variableName &&
        modelLayersRef.current !== undefined &&
        modelLayersRef.current.length !== 0
      ) {
        map.current.modelVariable = variableName;
        removeLayers(modelLayersRef.current);
        reorderLayers();
      } else if (map.current.modelVariable === undefined) {
        // initial load, no need to remove old layer
        map.current.modelVariable = variableName;
        reorderLayers();
      }
      if (modelLayersRef.current && modelLayersRef.current.length >= 2) {
        const isIncluded = CIOPSWestVariables.includes(selectedVariableNameRef.current);
        modelLayersRef.current.forEach((layer, index) => {
          if ((isIncluded && index >= 6) || (!isIncluded && index >= 3)) {
            // keep an extra layer to prevent flashing (no data) on play. i.e. the new layers covers the old one
            removeLayers([layer]);
          }
        });
      }
      if (isInsideEndOfDataRef.current) {
        if (!map.current.getSource(sourceID)) {
          const { tileName, modelRun } = getTileName(
            modelLongName,
            dateTimeRef.current,
            tileTimesLookupRef.current,
            selectedVariableNameRef.current
          );

          const tileURL = `${tilesURLPrefix}/${modelLongName}/${modelRun}/${selectedVariableNameRef.current}/${tileName}/{z}/{x}/{y}.webp`;
          // if this is CIOPS, get the West version as well
          let tileURLWest;
          if (modelLongName === 'CIOPS-SalishSea') {
            tileURLWest = tileURL.replace(/SalishSea/g, 'West');
          }

          // sourceID doesn't exist, so add
          map.current.addSource(sourceID, {
            type: 'raster',
            tiles: [tileURL],
            maxzoom,
          });
          loadingSourceRef.current = sourceID;
          if (tileURLWest) {
            const westSourceID = sourceID + 'West';
            const westLayerID = layerID + 'West';

            map.current.addSource(westSourceID, {
              type: 'raster',
              tiles: [tileURLWest],
              maxzoom,
            });
            loadingSourceWestRef.current = westSourceID;
            map.current.addLayer(
              {
                id: westLayerID,
                type: 'raster',
                source: westSourceID,
              },
              'landbase'
            );
          }

          map.current.addLayer(
            {
              id: layerID,
              type: 'raster',
              source: sourceID,
            },
            'landbase'
          );
        }
        // update animation layer if isVector and showAnimations
        if (isVector && showAnimationsRef.current) {
          updateVectorAnimationLayer();
        } else {
          removeAnimationLayers();
        }
      } else if (modelLayersRef.current && modelLayersRef.current.length !== 0) {
        removeLayers(modelLayersRef.current);
        removeAnimationLayers();
      }
    }
  }

  /*
  The map lifecycle is very complex.
  Initial load (in no order):
  - map is created
  - map background is added
  - cookies are read in App.jsx and passed in
  - map is styled based on cookie value
  - either default map layer is added, or cookie map layers are added

  On variable change (in no order):
  - map layers updated
  - map style updated

  On time change:
  - map layers updated
    - if: past end of data, remove layers
    - else: update layers

  There are situations where we are waiting on mutliple things to happen
  before we can call some functions.

  This means that there are times when we will be calling functions with and 
  without the preconditions being met, so functions must handle incorrect state.

  It also means that we must trigger the entire map render process from multiple locations
  because there are multiple pathways to the desired outcome. 

  Therefore, we both want to call the entire process from multiple triggers,
  but we also need to have the process broken out into smaller functions so that
  we can call portions of the process for the variable-change based triggers.
  
  Additional considerations:
  - because the map's functions are scoped to the context of when they are initialized,
  and do not read in parameters which change, variables that are going to change and be read
  must be stored in refs, and then read from the refs in the functions. This means that we must
  use useEffect to update the refs when the variables change so that the new values are available
  for use inside the functions. This is a major source of complexity in the map component, but 
  is an appropriate use of refs and useEffect in the mapbox-gl library.
  - In practice it is worth noting that this means that a lot of our functions are not pure,
  they do not take in clear parameters and return a value, but instead read from refs and write to refs,
  and also cause side effects. This is a necessary evil in the context of the mapbox-gl library.
  - It also means that functions that are going to be called by event handlers and also from 
  regular functions must utilize the ref version of a variable because they will need that context 
  when called from the event handler. Our most important lifecycle functions are called from event handlers,
  and so the ref pattern is necessary.
  */

  const loadingSourceRef = useRef(false);
  const loadingSourceWestRef = useRef(false);

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/hakai/cltdijsxg00pc01o890vmb1bx', // stylesheet location
      center: [-123.3, 49], // starting position [lng, lat]
      zoom: 6.75, // starting zoom
      projection: 'mercator',
      cluster: true,
      minZoom: 4.5,
      renderWorldCopies: false,
      attributionControl: false,
    });
    map.current.on('load', () => {
      const server =
        process.env.API_URL ||
        'https://a0t6h1frq6.execute-api.us-east-1.amazonaws.com/dev';
      // console
      const scale = new mapboxgl.ScaleControl({
        maxWidth: 55,
        unit: settings.units.scale,
      });

      map.current.addControl(scale, 'bottom-left');

      const SSVStationsAPI = '/stations/list/';

      stationTypes.forEach(({ type: symbolName, icon }) => {
        map.current.loadImage(stationIcons[symbolName], (error, image) => {
          if (error) throw error;
          // check if this image already exists
          if (!map.current.hasImage(`${symbolName}Icon`))
            map.current.addImage(`${symbolName}Icon`, image, { sdf: true });
        });
      });
      // const symbolName='All'
      // const symbolPath = icon;
      // const url = `${server}${SSVStationsAPI}all`;
      // const symbolData =
      // console.log('adding source for ', symbolName);

      // for each station type, fetch to api for that type, combine all with prmomise.all
      const allStations = [];
      const allStationPromises = stationTypes.map(({ type: symbolName }) => {
        const url = `${server}${SSVStationsAPI}${symbolName}`;
        return fetch(url);
      });
      // then get the geojson for each station
      Promise.all(allStationPromises)
        .then((responses) => {
          return Promise.all(responses.map((response) => response.json()));
        })
        .then((stationData) => {
          stationData.forEach((data) => {
            allStations.push(...data.features);
          });
          const geojson = {
            type: 'FeatureCollection',

            features: allStations,
          };

          map.current.addSource(`AllStationsSource-zoomed`, {
            type: 'geojson',
            data: geojson,
            generateId: true,
            cluster: true,
            clusterMaxZoom: 14,
            clusterRadius: 35,
            minzoom: stationsShowZoomLevel,
          });

          map.current.addLayer({
            id: `AllStationsCircleLayer-zoomed`,

            type: 'circle',
            source: `AllStationsSource-zoomed`,
            layout: {
              visibility: 'none',
            },
            filter: ['!', ['has', 'point_count']],
            paint: {
              'circle-radius': 15,
              'circle-color': [
                'case',
                ['boolean', ['feature-state', 'click'], false],
                // theme.palette.icons[symbolName].light,
                'red',
                theme.palette.secondary.main,
              ],
              'circle-stroke-width': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                4,
                ['boolean', ['feature-state', 'click'], false],
                4,
                2,
              ],
              'circle-stroke-color': theme.palette.primary.main,
            },
          });

          map.current.addLayer({
            id: `AllStationsIconLayer-zoomed`,
            type: 'symbol',
            source: `AllStationsSource-zoomed`,
            layout: {
              visibility: 'none',
              'icon-image': ['concat', ['get', 'type'], 'Icon'],
              'icon-size': 0.5,
              'icon-allow-overlap': true,
            },
            paint: {
              'icon-color': theme.palette.primary.main,
            },
            filter: ['!', ['has', 'point_count']],
          });
          map.current.addLayer({
            id: `AllStationsClusterLayer-zoomed`,
            type: 'circle',
            source: `AllStationsSource-zoomed`,
            layout: {
              visibility: 'none',
            },
            filter: ['has', 'point_count'],
            paint: {
              'circle-radius': ['step', ['get', 'point_count'], 18, 5, 20, 10, 22],
              'circle-color': [
                'case',
                ['boolean', ['feature-state', 'click'], false],
                theme.palette.icons.webcams.light,
                // 'red',
                theme.palette.secondary.main,
              ],
              'circle-stroke-width': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                4,
                ['boolean', ['feature-state', 'click'], false],
                4,
                2,
              ],
              'circle-stroke-color': theme.palette.primary.main,
            },
          });

          map.current.addLayer({
            id: `AllStationsClusterCountLayer-zoomed`,
            type: 'symbol',
            source: `AllStationsSource-zoomed`,
            filter: ['has', 'point_count'],
            layout: {
              'text-field': ['get', 'point_count_abbreviated'],
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 12,
              visibility: 'none',
            },
          });
          // });

          map.current.on('click', `AllStationsClusterLayer-zoomed`, (e) => {
            if (!useCrosshairRef.current) clearMarkers();

            const features = map.current.queryRenderedFeatures(e.point, {
              layers: [`AllStationsClusterLayer-zoomed`],
            });
            const clusterId = features[0].properties.cluster_id;

            map.current
              .getSource(`AllStationsSource-zoomed`)
              .getClusterExpansionZoom(clusterId, (err, zoom) => {
                if (err) return;

                map.current.easeTo({
                  center: features[0].geometry.coordinates,
                  zoom,
                });
              });
          });
        });
      updateCrossHair();
    });
    map.current.addControl(
      new mapboxgl.AttributionControl({ compact: true }),
      'bottom-right'
    );

    window.addEventListener('resize', () => {
      if (showAnimationsRef.current) {
        updateVectorAnimationLayer();
      }
    });

    // disable map tilting using two-finger gesture
    map.current.touchPitch.disable();

    // disable map rotation using right click + drag
    map.current.dragRotate.disable();

    // disable map rotation using touch rotation gesture
    map.current.touchZoomRotate.disableRotation();

    map.current.setMaxBounds(defaultMaxBounds);

    map.current.once('load', () => {
      modelLayersRef.current = getModelLayers();
      addMapBackground();
      // There functions are probably meant to load the layers initiallly but they are being run after layers have already loaded, so removing
      // updateMapLayerStyle();
      // updateMapLayerSource();
      // cleanUpMapLayers();
      // reorderLayers();

      addStationEventHandlers();

      map.current.on('click', (event) => {
        setLandOrWater(checkLandOrWater(map.current, event.point));
        setMapCenterPoint([event.lngLat.lng, event.lngLat.lat]);
        if (useCrosshairRef.current) {
          easeMapTo(event.lngLat.lng, event.lngLat.lat);
        } else {
          setPointLocations([event.lngLat.lat, event.lngLat.lng]);
          if (!event.clickOnLayer) {
            // click on open map
            setMarker(event.lngLat.lat, event.lngLat.lng);
            clearSelectedStations();
          }
        }
      });
      initialLoad.current = true;
    });

    map.current.on('zoomstart', () => {
      handleMapInteractionStart();
      return () => {
        map.off('zoomstart');
      };
    });

    map.current.on('zoomend', () => {
      setZoom(map.current.getZoom());
      handleMapInteractionEnd();
      return () => {
        map.off('zoomend');
      };
    });

    map.current.on('movestart', () => {
      handleMapInteractionStart();
      return () => {
        map.off('movestart');
      };
    });

    map.current.on('moveend', () => {
      handleMapInteractionEnd();
      return () => {
        map.off('moveend');
      };
    });

    map.current.on('sourcedata', (e) => {
      if (e.sourceId === loadingSourceRef.current && e.isSourceLoaded) {
        if (!loadingSourceWestRef.current) {
          setMapDataLoaded(true);
        }
        loadingSourceRef.current = undefined;
      } else if (e.sourceId === loadingSourceWestRef.current && e.isSourceLoaded) {
        if (!loadingSourceRef.current) {
          setMapDataLoaded(true);
        }
        loadingSourceWestRef.current = undefined;
      }
    });

    map.current.on('data', (e) => {
      if (map.current.isStyleLoaded()) {
        setMapStyleLoaded(true);
      }
    });
  }, []);

  return <div ref={mapContainer} id="map" />;
}
