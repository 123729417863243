import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Fab,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import DataElement from './DataElement.jsx';
import {
  formatNumberGenerator,
  getArrowDirection,
  getNewUnits,
  hexToRgb,
  roundNumber,
} from '../../utilities';
import mapLayers from '../../mapLayers.js';
import { scaleLinear } from 'd3-scale';
import { ArrowUpward, Style, Close, SsidChartRounded } from '@mui/icons-material';
import TideIndicator from '../TideIndicator.jsx';
import { StyledSkeleton } from '../StyledComponents.jsx';

export default function DataElementStrip({
  selectedVariableName,
  variableData,
  dataLoading = false,
  changeUnits,
  minTide,
  maxTide,
  settings,
  width,
  useDataColor = false,
  backgroundColor,
  setDataPanelOpen,
  showForecastButton: isDataDrawer,
  noDataReason,
}) {
  const theme = useTheme();

  const { high, value, low, direction, gust, period, change } = variableData;

  const selectedMapLayer = mapLayers.find((e) => e.variableName === selectedVariableName);
  const { displayUnits, name: unitsSelected } = getNewUnits(
    selectedMapLayer.unit,
    settings.units
  );

  const arrowDirection = getArrowDirection(selectedVariableName, direction);

  const selectedMapVar = mapLayers.find((e) => e.variableName === selectedVariableName);
  const { scale } = selectedMapVar;
  let dataGradient;
  if (settings.colorData && useDataColor) {
    dataGradient = scaleLinear()
      .domain(scale.map((step) => step.value))
      .range(scale.map((step) => step.color))
      .clamp(true)
      .unknown(`rgb(${hexToRgb(theme.palette.secondary.main)})`);
  } else {
    dataGradient = () => `rgb(${hexToRgb(backgroundColor)})`;
  }

  const formatNumber = formatNumberGenerator(unitsSelected);

  const hasData = value !== undefined && value !== null && !isNaN(value);
  const hasGusts = gust !== undefined && gust !== null && !isNaN(gust);
  const hasPeriod = period !== undefined && period !== null && !isNaN(period);

  // Define consistent container styles
  const containerStyles = {
    width,
    paddingTop: '2px',
    paddingRight: '2px',
    minHeight: '70px', // Set a consistent minimum height
    height: '100%',
  };

  const gridContainerStyles = {
    height: '100%',
    minHeight: '70px', // Match the container minimum height
    display: 'flex',
    alignItems: 'center',
  };

  let dataElement1, dataElement2, dataElement3;
  switch (selectedVariableName) {
    case 'potentialTemperature':
      dataElement1 = (
        <DataElement
          value={formatNumber(roundNumber(high))}
          label={'daily high'}
          units={displayUnits}
          dataColor={dataGradient(high)}
          noData={!hasData}
          variant="h5"
        />
      );
      dataElement2 = (
        <DataElement
          value={formatNumber(value)}
          label={'sea temperature'}
          dataColor={dataGradient(value)}
          units={displayUnits}
          noData={!hasData}
          variant="h4"
          backgroundColor={backgroundColor}
        />
      );
      dataElement3 = (
        <DataElement
          value={formatNumber(roundNumber(low))}
          label={' daily low'}
          units={displayUnits}
          dataColor={dataGradient(low)}
          noData={!hasData}
          variant="h5"
        />
      );
      break;

    case 'wave':
      dataElement1 = (
        <DataElement label={'direction'} direction={arrowDirection} noData={!hasData} />
      );
      dataElement2 = (
        <DataElement
          value={formatNumber(value)}
          label={'wave height'}
          dataColor={dataGradient(value)}
          units={displayUnits}
          noData={!hasData}
          variant="h4"
          backgroundColor={backgroundColor}
        />
      );
      dataElement3 = (
        <DataElement
          value={Number(period).toFixed(0)}
          label={'period'}
          units={'s'}
          underlineUnits={false}
          noData={hasData && !hasPeriod}
          variant="h5"
        />
      );
      break;

    case 'airTemperature':
      dataElement1 = (
        <DataElement
          value={formatNumber(roundNumber(high))}
          label={'daily high'}
          dataColor={dataGradient(high)}
          units={displayUnits}
          noData={!hasData}
          variant="h5"
        />
      );
      dataElement2 = (
        <DataElement
          value={formatNumber(value)}
          label={'air temperature'}
          dataColor={dataGradient(value)}
          units={displayUnits}
          noData={!hasData}
          variant="h4"
          backgroundColor={backgroundColor}
        />
      );
      dataElement3 = (
        <DataElement
          value={formatNumber(roundNumber(low))}
          label={'daily low'}
          dataColor={dataGradient(low)}
          units={displayUnits}
          noData={!hasData}
          variant="h5"
        />
      );
      break;

    case 'current':
      dataElement1 = (
        <DataElement label={'direction'} direction={direction} noData={!hasData} />
      );
      dataElement2 = (
        <DataElement
          value={formatNumber(value)}
          label={'current speed'}
          dataColor={dataGradient(value)}
          units={displayUnits}
          noData={!hasData}
          variant="h4"
          backgroundColor={backgroundColor}
        />
      );
      break;

    case 'wind':
      dataElement1 = (
        <DataElement label={'direction'} direction={direction} noData={!hasData} />
      );
      dataElement2 = (
        <DataElement
          value={formatNumber(value)}
          label={'wind speed'}
          dataColor={dataGradient(value)}
          units={displayUnits}
          noData={!hasData}
          variant="h4"
          backgroundColor={backgroundColor}
        />
      );
      dataElement3 = (
        <DataElement
          value={formatNumber(gust)}
          label={'gust'}
          dataColor={dataGradient(gust)}
          units={displayUnits}
          noData={hasData && !hasGusts}
          variant="h5"
        />
      );
      break;

    case 'seaSurfaceHeight':
      dataElement1 = <DataElement value={change} label={'direction'} noData={!hasData} />;
      dataElement2 = (
        <DataElement
          value={formatNumber(value)}
          label={'tide height'}
          dataColor={dataGradient(value)}
          units={displayUnits}
          noData={!hasData}
          variant="h4"
          backgroundColor={backgroundColor}
        />
      );
      dataElement3 = (
        <Stack justifyContent="flex-end" alignItems="center" sx={{ height: '100%' }}>
          <Stack direction="row" alignItems="flex-end" spacing={0.5}>
            {dataLoading ? (
              <Box sx={{ height: '42px' }}>
                <CircularProgress color="secondary" size={45} thickness={3} />
              </Box>
            ) : hasData &&
              maxTide !== undefined &&
              minTide !== undefined &&
              value !== undefined ? (
              <TideIndicator maxTide={maxTide} minTide={minTide} currentTide={value} />
            ) : (
              <Typography variant="h6" color="secondary">
                No data
              </Typography>
            )}
          </Stack>
          <Typography variant="caption" color="secondary">
            tide level
          </Typography>
        </Stack>
      );
      break;

    default:
      break;
  }

  if (dataLoading) {
    return (
      <Stack direction="row" alignItems="center" sx={containerStyles}>
        <Grid container>
          <Grid item container xs={12} justifyContent="center">
            <StyledSkeleton
              animation="wave"
              sx={{
                height: '66px',
                width: '98%',
                backgroundColor: theme.palette.primary.dark,
                borderRadius: '20px',
              }}
              variant="rectangular"
            />
          </Grid>
        </Grid>
      </Stack>
    );
  }

  if (!hasData) {
    let noDataReasonText;
    if (isDataDrawer) {
      noDataReasonText =
        noDataReason === 'location'
          ? selectedMapLayer.variableName === 'potentialTemperature'
            ? `No ${selectedMapLayer.displayNameShort.toLowerCase()} data at this location`
            : `No ${selectedMapLayer.displayName.toLowerCase()} data at this location`
          : `No ${selectedMapLayer.displayName.toLowerCase()} data at the selected time`;
    } else {
      noDataReasonText = 'No data';
    }

    return (
      <Stack direction="row" alignItems="center" sx={containerStyles}>
        <Grid container sx={gridContainerStyles}>
          <Grid item container xs={12} justifyContent="center" alignItems="center">
            <Typography variant="h6" color="secondary">
              {noDataReasonText}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    );
  }

  const valueButtonStyle = {
    textTransform: 'none',
    borderRadius: '5px',
    padding: '0px',
    width: '100%',
    height: '100%',
    minHeight: '100%',
    border: '2px solid #ffffff00',
  };

  return (
    <Stack direction="row" alignItems="center" sx={containerStyles}>
      <Grid container sx={gridContainerStyles}>
        <Grid item xs={4} justifyContent="center">
          <Button
            onClick={() => changeUnits(selectedVariableName)}
            variant="text"
            sx={valueButtonStyle}
            TouchRippleProps={{ style: { color: theme.palette.secondary.main } }}
          >
            {dataElement2}
          </Button>
        </Grid>

        <Grid
          item
          xs={isDataDrawer ? 2 : 4}
          justifyContent="center"
          sx={{ border: '2px solid #ffffff00' }}
        >
          <Button
            onClick={() => changeUnits(selectedVariableName)}
            variant="text"
            sx={valueButtonStyle}
            TouchRippleProps={{ style: { color: theme.palette.secondary.main } }}
            disabled={
              !['potentialTemperature', 'airTemperature'].includes(selectedVariableName)
            }
          >
            {dataElement1}
          </Button>
        </Grid>

        <Grid
          item
          xs={isDataDrawer ? 2.5 : 4}
          justifyContent="center"
          sx={{
            border: '2px solid #ffffff00',
          }}
        >
          <Button
            onClick={() => changeUnits(selectedVariableName)}
            variant="text"
            sx={valueButtonStyle}
            TouchRippleProps={{ style: { color: theme.palette.secondary.main } }}
            disabled={
              !['potentialTemperature', 'airTemperature', 'wind'].includes(
                selectedVariableName
              )
            }
          >
            {dataElement3}
          </Button>
        </Grid>

        {isDataDrawer && (
          <Grid
            item
            xs={3.5}
            alignContent="center"
            textAlign={'right'}
            paddingRight={'2px'}
            onClick={() => {
              setDataPanelOpen(true);
              window.clarity('event', 'Charts open');
            }}
          >
            <Fab
              variant="extended"
              sx={{
                color: 'white',
                backgroundColor: '#023555',
                boxShadow: 'none',
                border: '0.5px solid grey',
                padding: '1.5px',
                '&:hover': {
                  backgroundColor: '#023555',
                },
              }}
            >
              <SsidChartRounded sx={{ padding: '0px' }} />
              <Typography variant="body2" sx={{ fontSize: '0.75rem', padding: '0px' }}>
                {selectedMapLayer.displayNameShort} Chart
              </Typography>
            </Fab>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
}
